import { ActionIcon, createTheme, Paper, rem } from '@mantine/core';

export const theme = createTheme({
  fontFamily: 'Inter, sans-serif',
  // Default colors are defined at https://yeun.github.io/open-color/
  colors: {
    pink: [
      '#ff66d9',
      '#ff4dd2',
      '#ff33cc',
      '#ff1ac5',
      '#ff00bf',
      '#e600ac',
      '#cc0099',
      '#b30085',
      '#990070',
      '#80005d',
    ],
    lime: [
      '#69ff66',
      '#50ff4d',
      '#37ff33',
      '#1eff1a',
      '#05ff00',
      '#05e600',
      '#04cc00',
      '#04b300',
      '#039900',
      '#038000',
    ],
    blue: [
      '#66b0ff',
      '#4da3ff',
      '#3395ff',
      '#1a88ff',
      '#007bff',
      '#006fe6',
      '#0062cc',
      '#0056b3',
      '#004a99',
      '#003e80',
    ],
    red: [
      '#fd948b',
      '#fc8277',
      '#fc7164',
      '#fb5f50',
      '#fb4d3d',
      '#e24537',
      '#c93e31',
      '#b0362a',
      '#972e23',
      '#7e261c',
    ],
  },
  radius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
  },
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },

  headings: {
    sizes: {
      h1: { fontSize: '1.75rem' },
      h2: { fontSize: '1.5rem' },
      h3: { fontSize: '1rem' },
    },
  },
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        color: 'gray',
        variant: 'subtle',
      },
      styles: {
        root: {
          // Override default 'button' styles.
          boxShadow: 'none',
          border: 'none',
          margin: 0,
        },
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        shadow: 'xs',
      },
    }),
  },
});
