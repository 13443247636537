import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import './SiteHeader.css';
import PlusButton from '../PlusButton/PlusButton';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { SelectOption } from '../types';
import MenuIcon from '@mui/icons-material/Menu'; // <-- Import the hamburger icon
import { useMediaQuery } from '@mui/material'; // <-- Import to check screen size

const Header = ({ openMobileMenu }: { openMobileMenu: () => void }) => {
  // <-- Accept openMobileMenu prop
  const history = useHistory();
  const authSession = useContext(AuthenticatedSessionContext);
  const firmDb = authSession?.db;
  const [allMatters, setAllMatters] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
  const isMobile = useMediaQuery('(max-width: 768px)'); // <-- Check if it's mobile view

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 24,
      borderColor: '#CCCCCC',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#FF00BF',
      },
    }),
    container: (provided: any) => ({
      ...provided,
      width: '240px',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
  };

  useEffect(() => {
    const fetchAllMatters = async () => {
      if (!firmDb) {
        return;
      }
      const snapshot = await firmDb.getMatters([]);
      const mattersList = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.data().name ?? '',
        scope: doc.data().scope ?? '',
      }));
      setAllMatters(mattersList);
    };

    fetchAllMatters();
  }, [firmDb]);

  const loadOptions = async (inputValue: string): Promise<SelectOption[]> => {
    return allMatters.filter((matter) =>
      matter.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleChange = (selectedOption: SelectOption) => {
    history.push(`/matter/${selectedOption.value}`);
    setSelectedOption(null); // Clear the search box
  };

  const formatOptionLabel = ({ label, scope }: SelectOption) => (
    <div>
      <div>{label}</div>
      {scope && <div style={{ fontSize: '0.8em', color: 'gray' }}>{truncateMiddle(scope, 80)}</div>}
    </div>
  );

  return authSession ? (
    <>
      <div className='site-header'>
        {isMobile && (
          <MenuIcon
            className='hamburger-icon'
            onClick={() => {
              console.log('Hamburger menu clicked');
              openMobileMenu();
            }}
            style={{ position: 'absolute', top: '20px', left: '20px', cursor: 'pointer' }}
          />
        )}
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onInputChange={(newValue) => newValue}
          placeholder='Search for a matter...'
          styles={customStyles}
          onChange={(val) => handleChange(val as SelectOption)}
          formatOptionLabel={formatOptionLabel}
          value={selectedOption} // Control the value of the select
        />
        <PlusButton />
      </div>
    </>
  ) : null;
};

export default Header;

function truncateMiddle(text: string | null, maxChars: number): string {
  if (!text || text.length <= maxChars) {
    return text || '';
  }
  const availableChars = maxChars - 3; // -3 for the ellipsis
  const prefixLength = Math.ceil(availableChars / 2);
  const suffixLength = availableChars - prefixLength;

  return `${text.substring(0, prefixLength)}...${text.substring(text.length - suffixLength)}`;
}
